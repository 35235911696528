export const AdminSideBarData = [
    {
        title:"News",
        link:"news"
    },
    {
        title:"Clients",
        link:"clients"
    },
    {
        title:"Partners",
        link:"partners"
    },
    {
        title:"Testimonials",
        link:"testimonials"
    },
    {
        title:"Projects",
        link:"projects"
    },
    {
        title:"Courses",
        link:"courses"
    },
    {
        title:"Careers",
        link:"careers"
    },
    // {
    //     title:"Slider",
    //     link:"slider"
    // },
]