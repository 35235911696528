import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { Context } from "../index";
import { FormattedMessage, IntlProvider } from "react-intl";
import { messageTable } from "../i18n/MessageTable";
import { LOCALES } from "../i18n/Locale";
import Breadcrumbs from "../components/Breadcrumbs";
import NewsTable from "../components/NewsTable";

const ProjectCard = observer(() => {
    const { id } = useParams();
    const { locale, project } = useContext(Context);
    const [currentProject, setCurrentProject] = useState(project.getProject());
    console.log(JSON.stringify(currentProject[id].title));
    console.log(locale);
    return (
        <div>
            <IntlProvider
                messages={messageTable[locale.getLocale()]}
                locale={locale.getLocale()}
                defaultLocale={LOCALES.ENGLISH}
            >
                <div className={"center_container"}>
                    <div className={"container"}>
                        <Breadcrumbs />
                        <div>
                            {currentProject ?
                                (<>
                                <div
                                    style={{ fontWeight: 600, color: "black", fontSize: "26px" }}>
                                    {currentProject[id].title[locale.getLocale()]}
                                </div>
                                <div className={"newscard_text"} dangerouslySetInnerHTML={{__html:currentProject[id].text[locale.getLocale()] }}/>
                                </>): <></>}
                                </div>

                        </div>
                    </div>
            </IntlProvider>
        </div>
);
});

export default ProjectCard;
