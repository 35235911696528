import {LOCALES} from "./Locale";

export const MessageHeader = {
    [LOCALES.ENGLISH]:{
        who_we_are:`Who we are`,
        about_us:`About us`,
        foundation:`Foundation`,
        partners:`Partners`,
        membership:`Membership`,
        police:`Polices & Strategies`,
        initiation:"Initiation & Conception",
        references:`References`,
        what_we_do:`What we do`,
        services:`Services`,
        sectors:`Sectors`,
        plan:`Plan`,
        design:`Design`,
        deliver:`Deliver`,
        manage:`Manage`,
        decommission:`Decommission`,
        water_and_environment:`Water and Environment`,
        energy_and_resources:`Energy and Resources`,
        transport:`Transport`,
        telecommunication:`Telecommunication`,
        where_we_work:`Where We work`,
        news:`News`,
        education:`Education`,
        careers:`Careers`,
        contact:`Contact`,
    },
    [LOCALES.RUSSIAN]:{
        who_we_are:`Кто мы есть`,
        about_us:`Что мы делаем`,
        foundation:`Учреждение`,
        partners:`Партнёры`,
        membership:`Членство`,
        police:`Политика и стратегии`,
        references:`Рекомендации`,
        what_we_do:`Что мы делаем`,
        services:`Услуги`,
        sectors:`Сектора`,
        plan:`План`,
        design:`Дизайн`,
        deliver:`Доставка`,
        initiation:"Инициация & концепт",
        manage:`Управлять`,
        decommission:`Вывод из эксплуатации`,
        water_and_environment:`Вода и окружающая среда`,
        energy_and_resources:`Энергия и ресурсы`,
        transport:`Транспорт`,
        telecommunication:`Телекоммуникации`,
        news:`Новости`,
        education:`Обучение`,
        careers:`Вакансии`,
        contact:`Контакты`,
    },
    [LOCALES.UZBEK]:{
        who_we_are:`Биз киммиз`,
        about_us:`Биз ҳақимизда`,
        foundation:`Таъсис этилиш`,
        partners:`Шериклар`,
        membership:`Аъзолик`,
        initiation:"Ташаббусчилик ва концептуаллаштириш",
        police:`Сиёсат ва стратегиялар`,
        references:`Тавсияномалар`,
        what_we_do:`Биз нима билан шуғулланамиз`,
        sectors:"Секторлар",
        services:"Хизматлар",
        plan:"Режа",
        design:"Лойиҳа",
        procurement:"Харидлар",
        deliver:"Етказиб бериш",
        decommission:"Эксплуатациядан чиқариш",
        water_and_environment:`Сув ва атроф муҳит`,
        energy_and_resources:`Энергетика ва ресурслар`,
        transport:`Транспорт`,
        telecommunication:`Телекоммуникация`,
        news:`Янгиликлар`,
        education:`Ўқитиш`,
        careers:`Вакансиялар`,
        contact:`Контактлар`,
    }

}