import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CreatePanel from "./CreatePanel";
import { LOCALES } from "../../../i18n/Locale";
import { observer } from "mobx-react-lite";

const ModalWindow = observer(({show,handleClose,data,newCourse,setNewCourse}) => {
    const temp = {
                name: {
                    [LOCALES.ENGLISH]: "",
                    [LOCALES.RUSSIAN]: "",
                    [LOCALES.UZBEK]: "",
                },
                text: {
                    [LOCALES.ENGLISH]: "",
                    [LOCALES.RUSSIAN]: "",
                    [LOCALES.UZBEK]: "",
                },
                position: {
                    [LOCALES.ENGLISH]: "",
                    [LOCALES.RUSSIAN]: "",
                    [LOCALES.UZBEK]: "",
                },
                date: "",
            }
    const [defaultData, setDefaultData] = useState(temp || data);
    const handleClick = () => {
      const obj = newCourse;
      // if (!data) {
          obj.feedback.push(defaultData);
      // }else{
      //     obj.feedback.slice(obj.feedback.indexOf(data),obj.feedback.indexOf(data)+1);
      //     obj.feedback.push(defaultData);
      // }
        obj.feedback.map((elem, index) => {
            elem.id = index + 1;
        });
        console.log(JSON.stringify(obj));
        setNewCourse(obj)
        setDefaultData(temp)
        handleClose();
    }
    return (
        <Modal show={show} onHide={handleClose} size={"sm"} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Create/Edit Panel</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CreatePanel
                    data={defaultData}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleClick}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default ModalWindow;
