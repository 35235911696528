import { LOCALES } from "./Locale";

export const messageSectors = {
    [LOCALES.ENGLISH]:{
        dams_and_reservoirs_title:"Dams and Reservoirs",
        environment_planning_and_assessment_title:"Environment planning and assessment",
        irrigation_and_melioration_title:"Irrigation and Melioration",
        desalination_title:"Desalination",
        rivers_title:"Rivers",
        sustainability_title:"Sustainability",
        water_resources_title:"Water resources",
        water_treatment_title:"Water treatment",
        water_transmission_title:"Water transmission",
        wastewater_title:"Wastewater",
        mining_and_processing_title:"Mining and Processing",
        hydro_and_thermal_power_title:"Hydro and thermal power",
        renewable_energy_title:"Renewable energy (solar, wind & biogaz)",
        hybrid_energy_systems_title:"Hybrid energy systems",
        transmission_and_distribution_title:"Transmission and Distribution",
        energy_storage_title:"Energy storage and Uninterruptible power supply systems",
        airports_title:"Airports",
        intelligent_transport_systems_title:"Intelligent Transport Systems",
        pavement_management_title:"Pavement management",
        public_transport_management_title:"Public Transport management",
        railways_and_metro_title:"Railways and Metro",
        roads_and_highways_title:"Roads and Highways",
        bridges_and_tunnels_title:"Bridges and Tunnels",
        traffic_and_transport_planning_title:"Traffic and Transport Planning",
        services:"Related Services",
        upcoming_related_courses:"Upcoming Related Courses",
        all_course:"all course",
        see_also:"See also",
        all_services:"All Services",
        //------------------------------------
        dams_and_reservoirs:"PCC provides jointly with its leading international partners dams and reservoirs advisory services including but not limited to  design, project management, construction supervision, safety management. Through our international and local teams of dams and reservoirs specialists, we deliver to our clients and partners the best solutions resulting in effective, proven and environmentally friendly implementation of projects.",
        environment_planning_and_assessment:`PCC pays great importance to environment and is committed to bring solutions which will benefit both environment and the project. Through environmental assessment and management, environmental planning and monitoring we bring environmentally sustainable solutions. Environment planning and assessment services can be provided independently, or in conjunction with other sectors including transport, water, mining and energy.`,
        irrigation_and_melioration:"Agriculture is one of the most important sector of the economy and almost entirely depends on good and timely irrigation and melioration. PCC is committed to bring quality, innovative and sustainable ",
        desalination:"Desalination is another issue for the agriculture and manufacturing. PCC offers individually tailored approaches based on experienced international practice and situation in places.",
        rivers:"PCC provides jointly with its leading international partners rivers advisory including but not limited to  design, project management, construction supervision, safety management. Through our international and local teams of embankments, spillways, gates, intakes, outlets and pipelines specialists, we deliver to our clients and partners the best solutions resulting in cost and time effectiveness and environmentally friendly implementation of projects.",
        sustainability:"Whatever service PCC provides, it is committed to use sustainability approach while achieving clients goals. PCC continuously work on updating, development and adopting best business practices on sustainability, especially in such core areas as energy and water. Because, client's expectation does matter, PCC involves experienced environmental planners, scientists, ecologists and engineers to solve sustainability issues and prepare sustainable project solutions.",
        water_resources:"Water is very important issue for Central Asia region, as well as Uzbekistan, therefore PCC is equipped to provide full range of tailored and innovative services throughout inception of the project till commissioning and while operation. Using modern tools and international experience PCC delivers sustainable water solutions aimed to satisfy clients expectations and tough environment requirements.",
        water_treatment:"Because of certain expertise in water issues, PCC can provide a full package of services on a turn-key basis or provide partial services on demand. Extensive experience in water treatment and sanitation systems let PCC to offer services for rural and urban water supply systems through to desalination and water treatment facilities.",
        water_transmission:"It is known that water is a very important issue for Uzbekistan where there are scattered arid areas all over the country where water can be transported over long distances to satisfy the demand of the local population and the industries. PCC is committed to provide sustainable, efficient and environment friendly solutions to ensure Clients' expectations and ecological aspects. ",
        wastewater:"Wastewater collection, transportation and treatment is a top environmental and social issue. PCC provides innovative and efficient solutions through its services to reach economic, environmental and social benefits of project implementation.",
        mining_and_processing:"PCC provides jointly with its leading international partners Mining and Processing advisory services including but not limited to design, project management, construction supervision, safety management. Through our international and local teams we deliver to our clients and partners the best solutions resulting in tailored, effective, and environmentally friendly implementation and optimized operation and maintenance of the projects.",
        hydro_and_thermal_power:"With our engineers extensive expertise in Hydro and Thermal power generation, PCC provides a full scope of advisory services from inception to commissioning and further operation and maintenance. Either it is a small power generation facility in a remote and unconnected community or the most advanced facility, PCC will offer tailored approach using modular, flexible and whole grid system connection solutions to be effective, socially and environment beneficial.",
        renewable_energy:"Ambitious and challenging direction of energy development valued for being renewable and carbon emission free solution. Using modern tools and international experience PCC delivers innovative, cost-effective and sustainable renewable energy solutions aimed to satisfy clients expectations.",
        hybrid_energy_systems:"Safe and stable energy supply is the most important question of energy supply security. At this end, integration of different energy supply sources into a properly controlled, effective and stable supply network is important. PCC provides tailored, innovative and reliable solutions in Hybrid Energy System development and operation.",
        transmission_and_distribution:"Sustainable utilization of generated energy depends on efficient, safe and low-loss transmission and distribution and is a part of energy supply security. PCC's expertise in energy sector projects allows to offer a full spectrum of advisory services on energy transmission and distribution issues from initiation up to commissioning, and further to operation and maintenance.",
        energy_storage:"Whether it is small local grid system in remote, unconnected community, or just a household with a demand for energy storage and uninterruptible power supply system, understanding of efficient, sustainable and innovative technologies in this rapidly developing sector makes sense. PCC's experienced staff constantly monitors, studies and analyzes the situation in renewable energy and energy storage sector and therefore provides tailored solutions which well work toward Clients expectations.",
        airports:"PCC provides jointly with its leading international partners aviation advisory services combining but not limited to engineering design, construction, operation and maintenance. Through our international and local teams of aviation specialists, we deliver to our clients and partners best solutions resulting in effective implementation of projects. ",
        intelligent_transport_systems:"Intelligent Transport systems directly effects living and working conditions in habitat area through improved traffic management, managed traffic jams, improved safety and an available parking space, as well as noise and air pollution control. By combination of the latest systems, processes and technologies PCC provides consultancy on a wide range of Intelligent Transport systems. ",
        pavement_management:"Pavement Management directly effects living and working conditions in habitat area through improved pavement management system, optimized road networks and improved safety. By combination of the latest systems and processes PCC provides consultancy on a wide range of Pavement Management. ",
        public_transport_management:"Public Transport is a vital city arterium which brings city to live and directly effects conditions of its habitats. Using innovative, tailored and environmentally friendly solutions PCC provides a full scope of traffic and public transport advisory services to city authorities. ",
        railways_and_metro:"PCC provides jointly with its leading international partners railway and metro advisory services combining but not limited to engineering design, construction, operation and maintenance. Through our international and local teams of railway specialists, we deliver to our clients and partners best solutions resulting in effective, innovative and environmentally friendly implementation of railway and metro projects. ",
        roads_and_highways:"Roads and Highways are crucial for any country development because quality and existence of road does effect on each sector of economy. Moreover, advanced design, comprehensive and sophisticated advisory solutions are crucial to meet future challenges that road and highway infrastructure will meet by time. PCC provides a wide range of engineering and consulting services, including but limited to feasibility studies, procurement, design, construction and supervision of road and highway projects, whether it is new or existing development.  ",
        bridges_and_tunnels:"Urban development, economic, social and environmental demands urges to use modern approach of using above-/undergraund space to help deliver efficient, safe and resilient solutions. Using innovative, safe, effective and resilient solutions PCC delivers implementation that satisfies challenges of modern society.",
        traffic_and_transport_planning:"PCC provides jointly with its leading international partners traffic and transport planning services. Through our international and local teams of transport specialists and traffic engineers, we deliver to our clients and partners tailored and the best solutions. ",
        telecommunication:"PCC provides jointly with its leading international partners Telecommunication advisory services combining but not limited to engineering design, construction, operation and maintenance. Through our international and local teams of telecommunication professionals, we deliver the best solutions resulting in effective implementation of telecommunication projects.",
        sectors_title:"Sectors",
        sectors_part1:"Pro-consult Company professionally delivers full scope of engineering and consulting services, including but not limited to initiation, plan, design, procurement, delivery and commission of the project.",
        sectors_part2:"PCC's expertise cover all main sectors of Uzbekistan economy thriving to develop, such as Water and Environment, Energy and Resources, Transport and Telecommunication.",
        sectors_part3:"PCC is committed to deliver tailored, effective and sustainable solutions resulting in successful project implementation and outcomes.",
        sectors_part4:"PCC is addicted to modern, innovative and practical solutions at task performing.",
        //------------------------------
        water_and_environment_title:`Water and Environment`,
        energy_and_resources_title:`Energy and Resources`,
        transport_title:`Transport`,
        telecommunication_title:"Telecommunication",
        plan:`Plan`,
        design:`Design`,
        deliver:`Deliver`,
        decommission:`Decommission`,
        initiation:"Initiation & Conception",
        procurement:"Procurement",
    },
    [LOCALES.RUSSIAN]:{
        dams_and_reservoirs_title:"Плотины и водохранилища",
        environment_planning_and_assessment_title:"Экологическое планирование и оценка",
        irrigation_and_melioration_title:"Ирригация и мелиорация",
        desalination_title:"Опреснение",
        rivers_title:"Реки",
        sustainability_title:"Устойчивость",
        water_resources_title:"Водные ресурсы",
        water_treatment_title:"Водоподготовка",
        water_transmission_title:"Водоснабжение",
        wastewater_title:"Сточные воды",
        mining_and_processing_title:"Горная и перерабатывающая промышленность",
        hydro_and_thermal_power_title:"Гидро и теплоэнергия",
        renewable_energy_title:"Возобновляемая энергия (солнце, ветер и биогаз)",
        hybrid_energy_systems_title:"Гибридные энергетические системы",
        transmission_and_distribution_title:"Передача и распределение",
        energy_storage_title:"Накопители энергии и системы бесперебойного питания",
        airports_title:"Аэропорты",
        intelligent_transport_systems_title:"Система умного транспорта",
        pavement_management_title:"Управление дорожным покрытием",
        public_transport_management_title:"Управление общественным транспортом",
        railways_and_metro_title:"Железные дороги и метро",
        roads_and_highways_title:"Дороги и автомагистрали",
        bridges_and_tunnels_title:"Мосты и тоннели",
        traffic_and_transport_planning_title:"Дорожное движение и транспортное планирование",
        services:"Услуги",
        sectors_title:"Сектора",
        see_also:"Смотреть также",
        all_services:"Сервисы",
        //------------------------------------
        sectors_part1:"Pro-consult Company профессионально предоставляет полный спектр инженерных и консультационных услуг, включая, но не ограничиваясь этим, инициирование, планирование, проектирование, закупку, доставку и ввод в эксплуатацию проекта.",
        sectors_part2:"Опыт PCC охватывает все основные отрасли экономики Узбекистана, которые процветают и развиваются, такие как Водоснабжение и охрана окружающей среды, Энергетика и ресурсы, Транспорт и Телекоммуникации.\n",
        sectors_part3:"PCC стремится предоставлять индивидуальные, эффективные и устойчивые решения, приводящие к успешной реализации проектов и получению результатов.",
        sectors_part4:"PCC стремится к современным, инновационным и практичным решениям при выполнении задач.",
        dams_and_reservoirs:"PCC совместно со своими ведущими международными партнерами оказывает консультационные услуги по возведению плотин и водохранилищ, включая, но не ограничиваясь проектированием, управлением проектом, надзором за строительством, управлением безопасностью. Благодаря нашим международным и местным специалистам по плотинам и водохранилищам, мы предлагаем нашим клиентам и партнерам лучшие решения, приводящие к эффективной и экологически безопасной реализации проектов. ",
        environment_planning_and_assessment:"PCC придает большое значение окружающей среде и стремится предложить решения, которые принесут пользу как окружающей среде, так и проекту. Благодаря экологической оценке и управлению, экологическому планированию и мониторингу мы предоставляем экологически устойчивые решения. Услуги по экологическому планированию и оценке могут быть предоставлены как отдельно, так и с услугами по другим секторам, включая транспорт, водные ресурсы, горнодобывающая промышленность и энергетика.",
        irrigation_and_melioration:"Сельское хозяйство является одним из важнейших секторов экономики и почти полностью зависит от качественного и своевременного орошения и мелиорации. PCC предлагает качественные, инновационные и устойчивые решения в области ирригации и мелиорации, основанные на передовом международном опыте. PCC сотрудничает с министерствами и ведомствами, чтобы развить и сделать отрасль более эффективной. ",
        desalination:"Опреснение воды это еще одна проблема для сельского хозяйства и производства. PCC предлагает индивидуальные подходы, основанные на опыте международной практики и ситуации на местах.",
        rivers:"Совместно со своими ведущими международными партнерами PCC предоставляет консультационные услуги по вопросам рек, включая, но не ограничиваясь проектированием, управлением проектом, надзором за строительством, управлением безопасностью. Благодаря нашим международным и местным специалистам по строительству набережных, водосбросов, затворов, водозаборов, водовыпусков и трубопроводов, мы предлагаем нашим клиентам и партнерам лучшие решения, обеспечивающие экономическую эффективность и эффективность по времени, а также экологическую безопасность реализации проектов. ",
        sustainability:"Все услуги предоставляемые компанией PCC, осуществляются на основании подхода устойчивого развития при достижении целей клиентов. PCC постоянно работает над обновлением, развитием и внедрением передового опыта в области устойчивого развития, особенно в таких базовых областях, как энергетика и водоснабжение. Учитывая значимость ожиданий клиента, PCC привлекает опытных специалистов по экологическому планированию, ученых, экологов и инженеров для решения вопросов устойчивого развития и подготовки устойчивых проектных решений.",
        water_resources:"Водный вопрос является весьма важным для Центрально-Азиатского региона, в частности Узбекистана, в этой связи PCC сосредоточена на предоставлении полного спектра индивидуальных и инновационных услуг по всем этапам реализации от инициирования до ввода в эксплуатацию проекта, и далее при эксплуатации. Используя современные инструменты и международный опыт, компания PCC предлагает устойчивые решения в области водоснабжения, направленные на удовлетворение ожиданий клиентов и жестких экологических требований.  ",
        water_treatment:"Благодаря опыту в вопросах водоснабжения, PCC может предоставить полный пакет услуг \"под ключ\" или оказать отдельные услуги по запросу. Обширный опыт в области систем водоподготовки и канализации позволяет PCC предлагать услуги для сельских и городских систем водоснабжения, вплоть до опреснительных и водоочистных сооружений.",
        water_transmission:"Известно, что водный вопрос является весьма важным для Узбекистана, где по всей стране имеются засушливые территории, куда вода может транспортироваться на больших расстояниях для удовлетворения потребностей местного населения и промышленности. Компания PCC стремится предоставлять устойчивые, эффективные и экологичные решения для удовлетворения ожиданий клиентов и экологических аспектов. ",
        wastewater:"Сбор, транспортировка и очистка сточных вод - одна из важнейших экологических и социальных вопросов. PCC предоставляет инновационные, эффективные и экологичные решения для достижения экономических и социальных выгод от реализации проектов.",
        mining_and_processing:"PCC вместе с ведущими международными партнерами оказывает консультационные услуги в области горной и перерабатывающей промышленности, включая, но не ограничиваясь проектированием, управлением проектом, надзором за строительством, управлением за безопасностью. При помощи международных и местных команд мы предоставляем нашим клиентам и партнерам лучшие решения, результатом которых является индивидуальная, эффективная и экологически безопасная реализация проекта, а также оптимизированная эксплуатация и техническое обслуживание.",
        hydro_and_thermal_power:"Благодаря обширному опыту наших инженеров в области гидро- и теплоэнергетики, PCC предоставляет полный спектр консультационных услуг от инициации до ввода в эксплуатацию проекта, а так же дальнейшей эксплуатации и технического обслуживания. Будь то небольшой объект по производству электроэнергии в отдаленном и неподключенном к основной сети населенном пункте или самый современный объект с подключением к общей сети, PCC предложит индивидуальный подход с использованием модульных, гибких и цельных решений по подключению к энергосистеме, которые будут эффективными, социально и экологически выгодными.",
        renewable_energy:"Амбициозное и сложное направление развития энергетики являющейся возобновляемым и не содержащим выбросов углекислого газа. Используя современные инструменты и международный опыт, PCC предлагает инновационные, экономически эффективные и устойчивые решения в области возобновляемой энергетики, направленные на удовлетворение ожиданий клиентов.",
        hybrid_energy_systems:"Безопасное и стабильное энергоснабжение является весьма важным вопросом безопасности энергоснабжения. Для этого важна интеграция различных источников энергоснабжения в надлежащим образом контролируемую, эффективную и стабильную сеть энергоснабжения. Компания PCC предлагает индивидуальные, инновационные и надежные решения в области разработки и эксплуатации гибридных энергетических систем.",
        transmission_and_distribution:"Устойчивое использование вырабатываемой энергии зависит от эффективной, безопасной передачи и распределения энергии с малыми потерями,  а также является вопросом безопасности энергоснабжения. Опыт PCC в проектах энергетического сектора позволяет предложить полный спектр консультационных услуг по вопросам передачи и распределения энергии, начиная с разработки проекта и заканчивая вводом в эксплуатацию, а также эксплуатацией и техническим обслуживанием.",
        energy_storage:"Будь то небольшая локальная энергосистема в отдаленном, не подключенном к сети населенном пункте или просто домохозяйство, нуждающееся в накопителе энергии и системе бесперебойного электроснабжения, понимание эффективных, устойчивых и инновационных технологий в этом быстро развивающемся секторе имеет смысл. Опытные сотрудники PCC постоянно отслеживают, изучают и анализируют ситуацию в секторе возобновляемой энергетики и накопления энергии, предлагают индивидуальные решения, которые оправдывают ожидания клиентов.",
        airports:"PCC совместно с ведущими международными партнерами оказывает консультационные услуги в области авиации, включая, но не ограничиваясь, инженерным проектированием, строительством, эксплуатацией и техническим обслуживанием. Благодаря нашим международным и местным командам авиационных специалистов, мы предлагаем нашим клиентам и партнерам лучшие решения, приводящие к эффективной реализации проектов. ",
        intelligent_transport_systems:"Система умного транспорта напрямую влияет на условия жизни и работы на территориях жизнедеятельности, благодаря улучшенному управлению дорожным движением, уменьшению пробок, повышению безопасности и доступности парковочных мест, а также борьбе с шумом и загрязнением воздуха. Благодаря сочетанию новейших систем, процессов и технологий PCC предоставляет консультации по широкому спектру внедрения систем умного транспорта. ",
        pavement_management:"Управление дорожным покрытием напрямую влияет на условия жизни и работы на территориях жизнедеятельности, благодаря улучшению системы управления дорожным покрытием, оптимизации дорожной сети и повышению безопасности. Благодаря сочетанию новейших систем и процессов PCC предоставляет консультации по широкому спектру вопросов управления дорожным покрытием. ",
        public_transport_management:"Общественный транспорт - это жизненно важная городская артерия, которая оживляет город и напрямую влияет на условия проживания. Используя инновационные, индивидуальные и экологически безопасные решения, PCC предоставляет городским властям полный спектр консультационных услуг в области дорожного движения и общественного транспорта. ",
        railways_and_metro:"PCC совместно с ведущими международными партнерами предоставляет консультационные услуги в области железных дорог и метрополитена, включая, но не ограничиваясь, инженерным проектированием, строительством, эксплуатацией и техническим обслуживанием. Благодаря нашим международным и местным командам специалистов в области железнодорожного транспорта, мы предлагаем нашим клиентам и партнерам лучшие решения, приводящие к эффективной, инновационной и экологически безопасной реализации проектов железных дорог и метрополитена.",
        roads_and_highways:"Дороги и автомагистрали имеют решающее значение для развития любой страны, поскольку наличие и качество дорог влияет на каждый сектор экономики. Более того, современный дизайн, комплексные и сложные решения имеют решающее значение для решения будущих задач, с которыми со временем столкнется инфраструктура дорог и автомагистралей. PCC предоставляет широкий спектр инженерных и консультационных услуг, включая, но не ограничиваясь, технико-экономическим обоснованием, закупкой, проектированием, строительством и надзором за проектами дорог и автомагистралей, как новых, так и уже существующих.",
        bridges_and_tunnels:"Развитие городов, экономические, социальные и экологические требования требуют применения современных подходов при использовании над-/подповерхностного пространства для обеспечения эффективных, безопасных и устойчивых решений. Используя инновационные, безопасные, эффективные и устойчивые решения, компания PCC обеспечивает реализацию, отвечающую вызовам современного общества.",
        traffic_and_transport_planning:"PCC совместно с ведущими международными партнерами предоставляет услуги в области дорожного движения и транспортного планирования. Благодаря нашим международным и местным командам специалистов по транспорту и инженеров по организации дорожного движения, мы предлагаем нашим клиентам и партнерам индивидуальные и наилучшие решения. ",
        telecommunication:"PCC совместно с ведущими международными партнерами предоставляет консультационные услуги в области телекоммуникаций, включая, но не ограничиваясь, инженерным проектированием, строительством, эксплуатацией и техническим обслуживанием. Благодаря нашим международным и местным командам профессионалов в области телекоммуникаций, мы предлагаем лучшие решения, приводящие к эффективной реализации телекоммуникационных проектов.",
        upcoming_related_courses:"Предстоящие курсы",
        all_course:"Все курсы",
        //------------------------
        telecommunication_title:"Телекоммуникации",
        water_and_environment_title:`Вода и окружающая среда`,
        energy_and_resources_title:`Энергия и ресурсы`,
        transport_title:`Транспорт`,
        plan:`План`,
        design:`Дизайн`,
        deliver:`Доставка`,
        decommission:`Вывод из эксплуатации`,
        initiation:"Инициация и концепция",
        procurement:"Закупка"
    },
    [LOCALES.UZBEK]:{
        dams_and_reservoirs_title:"Тўғон ва сув омборлари",
        environment_planning_and_assessment_title:"Экологик режалаштириш ва баҳолаш",
        irrigation_and_melioration_title:"Ирригация ва мелиорация",
        desalination_title:"Чучуклаштириш",
        rivers_title:"Дарёлар",
        sustainability_title:"Барқарорлик",
        water_resources_title:"Сув ресурслари",
        water_treatment_title:"Сувни тозалаш",
        water_transmission_title:"Сув таъминоти",
        wastewater_title:"Оқава сувлар",
        mining_and_processing_title:"Тоғ-қон қазиш ва қайта ишлаш",
        hydro_and_thermal_power_title:"Гидро ва иссиқлик энергияси",
        renewable_energy_title:"Қайта тикланадиган энергия (қуёш, шамол ва биогаз)",
        hybrid_energy_systems_title:"Дурагай энергетик тизмлар",
        transmission_and_distribution_title:"Узатиш ва тарқатиш",
        energy_storage_title:"Қувватни сақлаш ва узлуксиз қувват таъминоти тизимлари",
        airports_title:"Аэропортлар",
        intelligent_transport_systems_title:"Ақлли транспорт тизимлари",
        pavement_management_title:"Йўл қопламаларини бошқариш",
        public_transport_management_title:"Жамоат транспортини бошқариш",
        railways_and_metro_title:"Темир ва ер ости (метро) йўли",
        roads_and_highways_title:"Йўллар ва автомагистраллар",
        bridges_and_tunnels_title:"Кўприклар ва тонеллар",
        traffic_and_transport_planning_title:"Йўл ҳаракати ва транспортни режалаштириш",
        services:"Хизматлар",
        upcoming_related_courses:"Бўлажак тематик курслар",
        all_course:"Ҳамма курслар",
        see_also:"See also",
        all_services:"Хизматлар",
        //------------------------------------
        dams_and_reservoirs:"PCC етакчи халқаро шериклар билан биргаликда тўғон ва сув омборларини қуриш бўйича консалтинг хизматларини кўрсатади, шу жумладан, лекин бу билан чекланмаган холда  лойиҳалаштириш, лойиҳаларни бошқариш, қурилишни назорат қилиш, хавфсизликни бошқариш ишларини амалга оширишда хизмат кўрсатади. Тўғонлар ва сув омборларини қуриш йўналишидаги халқаро ва маҳаллий мутахассисларимиз ёрдамида биз мижозларимизга ва шерикларимизга лойиҳаларни самарали ва экологик хавфсиз амалга оширишга олиб келадиган энг яхши ечимларни кўрсатиб берамиз.",
        environment_planning_and_assessment:"PCC атроф-муҳитга катта аҳамият беради ва шунинг учун экологик хавфсиз, шу билан бирга лойиҳани самарадорлигини юқори даражада амалга оширишга олиб келадиган ечимларни таклиф қилади. Экологик баҳолаш ва бошқариш, экологик вазиятни режалаштириш ва мониторинг қилиш орқали биз экологик барқарор ечимларни таклиф қиламиз. Экологик режалаштириш ва баҳолаш хизматлари алоҳида ёки транспорт, сув ресурслари, тоғ-кон саноати ва энергетика каби бошқа соҳалардаги хизматлар билан биргаликда бажарилиши мумкин.",
        irrigation_and_melioration:"Қишлоқ хўжалиги иқтисодиётнинг энг муҳим соҳаларидан бири бўлиб, тўғридан-тўғри яхши ва ўз вақтида суғориш ва мелиорацияга боғлиқ. PCC илғор халқаро амалиётини кўллаган ҳолда сифатли, инновацион ва барқарор ирригация ва мелиорация ечимларини таклиф қилади.\n" +
            "PCC саноатни ривожлантириш ва янада самарали қилиш учун вазирликлар ва идоралар билан ҳамкорлик қилади.",
        desalination:"Сувни тузсизлантириш (чучук сув) бу қишлоқ хўжалиги ва ишлаб чиқариш соҳалари учун жиддий масала. PCC тажрибали халқаро амалиёт ва жойлардаги вазиятга қараб индивидуал равишда мослаштирилган ёндашувларни таклиф этади.",
        rivers:"PCC етакчи халқаро шериклар билан биргаликда дарё масалалари бўйича консалтинг хизматларини тақдим этади, шу жумладан, лекин бу билан чегараланмаган ҳолда лойиҳалаштириш, лойиҳаларни бошқариш, қурилишни назорат қилиш, хавфсизликни бошқариш каби хизматларни кўрсатади. Сув бўйи, сув тушуриш, сув йиғиш ва чиқариш жойлари, қувурларни қуриш бўйича халқаро ва маҳаллий мутахассисларимиз ёрдамида биз мижозларимизга ва шерикларимизга иқтисодий самарадор, қисқа муддатли, шунингдек лойиҳани амалга оширишда экологик хавфсизлигини таъминлайдиган энг яхши ечимларни таклиф қиламиз.",
        sustainability:"PCC етакчи халқаро шериклар билан биргаликда дарё масалалари бўйича консалтинг хизматларини тақдим этади, шу жумладан, лекин бу билан чегараланмаган ҳолда лойиҳалаштириш, лойиҳаларни бошқариш, қурилишни назорат қилиш, хавфсизликни бошқариш каби хизматларни кўрсатади. Сув бўйи, сув тушуриш, сув йиғиш ва чиқариш жойлари, қувурларни қуриш бўйича халқаро ва маҳаллий мутахассисларимиз ёрдамида биз мижозларимизга ва шерикларимизга иқтисодий самарадор, қисқа муддатли, шунингдек лойиҳани амалга оширишда экологик хавфсизлигини таъминлайдиган энг яхши ечимларни таклиф қиламиз.",
        water_resources:"Сув Марказий Осиё минтақаси, шу жумладан Ўзбекистон учун жуда муҳим масаладир, шунинг учун PCC барча турдаги хизматларни, индивидуал ва инновацион ечимларни, барча лойиҳа амалга ошириш босқичлари, яъни ташаббусдан бошлаб лойиҳа ишга туширилгунга қадар кўрсатишга таёр.\n" +
            "Замонавий воситалар ва халқаро тажрибадан фойдаланган ҳолда, PCC мижозлари талабларини қондирадиган, юқори экологик талабларга жавоб берадиган, сув соҳасида барқарор ечимларни тақдим этади.",
        water_treatment:"Сув соҳасида муайян тажриба бўлганлиги туфайли, PCC \"под ключ\" (turn-key) асосида хизматларнинг тўлиқ тўпламини тақдим этиши ёки талаб бўйича алоҳида хизматларни тақдим этиши мумкин. Сувни тозалаш ва канализация тизимларида катта тажриба бўлганлиги сабаб, PCC қишлоқ ва шаҳар сув таъминоти тизимлари, ҳамда бу билан чегаранланмай тузсизлантириш ва сув тозалаш иншоотлари масалаларида хизмат кўрсатишга тайёр.",
        water_transmission:"Маълумки, маҳаллий аҳоли ва саноат тармоқлари талабини қондириш учун сув тизими узоқ масофаларга тарқалиши, ҳамда бутун мамлакат бўйлаб қурғоқчил ҳудудлар бўлган Ўзбекистон учун сув тизимини ривожлантириш жуда муҳим масаладир. PCC мижозларнинг умидлари ва экологик ечимларни таъминлаш учун барқарор, самарали ва атроф-муҳитга қулай таклифларни тақдим этади.",
        wastewater:"Оқава сувларни йиғиш, олиб кетиш ва тозалаш муҳим экологик ва ижтимоий масаладир. PCC лойиҳани амалга оширишда иқтисодий ва ижтимоий манфаатларни эришишга қаратилган инновацион, самарали ва экологик ечимларни тақдим этади.",
        mining_and_processing:"PCC етакчи халқаро шериклари билан биргаликда тоғ-кон қазиш ва қайта ишлаш ишларини ташкиллаштириш доирасида консалтинг хизматларини тақдим этади, шу жумладан, лекин чегараланмаган ҳолда лойиҳалаш, лойиҳаларни бошқариш, қурилишни назорат қилиш, хавфсизликни бошқариш каби хизматларни кўрсатади. Халқаро ва маҳаллий жамоаларимиз орқали мижозларимизга ва ҳамкорларимизга энг яхши ечимларни таклиф қилган ҳолда, лойиҳани самарали ва экологик хавфсиз амалга ошириш, шунингдек кейинчалик оптимал фойдаланишга ва техник хизмат кўрсатишга мослашган ечимларни теклиф қиламиз.",
        hydro_and_thermal_power:"Муҳандисларимизнинг гидроэнергетикада ва иссиқлик энергиясида кенг тажрибалари туфайли, PCC лойиҳани ташаббус ишларидан то ишга туширишгача бўлган барча консалтинг хизматларини кўрсатади, шунингдек кейинчалик фойдаланиш ва техник хизмат кўрсатиш мўбайнида керак бўладиган хизматларни ҳам кўрсатади. \n" +
            "Узоқ масофада жойлашган ва умумий тармоққа уланмаган аҳоли пунктида кичик электр энергия ишлаб чиқариш объекти бўладими, ёки энг илғор умумий тармоққа уланган объект бўладими, PCC самарали, ижтимоий ва экологик жиҳатдан маъқул бўлган натижага олиб келадиган модулли, қайишқоқ ва яхлит ечимларга асосланган тармоққа уланиш таклифларини индивидуал ёндашув орқали таклиф қилади.",
        renewable_energy:"Қайта тикланадиган ва углерод чиқимларисиз қувват ишлаб-чиқариш ва узатиш тизимини ташкил қилиш улкан ва қийин йўналишдир. Замонавий воситалар ва халқаро тажрибадан кенг фойдаланган ҳолда, PCC мижозларнинг умидларини қондиришга қаратилган инновацион, иқтисодий самарали ва барқарор ечимларини тақдим этади.",
        hybrid_energy_systems:"Хавфсиз ва барқарор қувват таъминоти энергия таъминоти хавфсизлигининг энг муҳим масаласидир. Шу мақсадда, турли хил энергия таъминоти манбаларини тўғри бошқара оладиган, самарали ва барқарор таъминот тармоғига бирлаштириш муҳимдир. PCC дурагай қувват тизимини ишлаб чиқиш ва ишлатишда якка, инновацион ва ишончли ечимларни тақдим этади.",
        transmission_and_distribution:"Ишлаб чиқарилган энергиядан барқарор фойдаланиш самарали, хавфсиз ва кам йўқотишли узатиш ва тарқатишга боғлиқ, ва қувват таъминоти хавфсизлигининг бир қисмидир. PCCнинг энергетика соҳасидаги лойиҳаларни амалга ошириш тажрибаси, энергия узатиш ва тарқатиш масалаларида кенг қўламли консалтинг хизмати, яъни лойиҳалаштиришдан тортиб то эксплуатация қилишгача, ундан кейин фойдаланиш ва техник хизмат кўрсатиш бўйича тўлиқ  хизматларини таклиф қилишга имкон беради.",
        energy_storage:"Узоқ масофада жойлашган умумий тизимга уланмаган кичик маҳаллий ёпиқ тизим бўладими ёки қувватни сақлаш ва узлуксиз қувват таъминоти тизимига муҳтож бўлган кичик аҳоли пункти бўладими, самарали, барқарор ва инновацион технологияларни тушуниш, бу шиддат билан ривожланаётган соҳада жуда муҳим. PCC нинг тажрибали ходимлари қайта тикланадиган қувват ва қувватни сақлаш соҳасида доимий тарзда назорат,  изланиш ва таҳлил қилиш ишларини олиб бориб, мижозлар кутвотган натижаларни кўзда тутган индивуал ечимларни таклиф қилишади.",
        airports:"PCC етакчи халқаро шериклари билан биргаликда авиация бўйича консалтинг хизматларини кўрсатади, шунингдек, лекин бу билан чегаранланмаган ҳолда муҳандис лойиҳалаштириш, қурилиши, эксплуатация қилиш ва техник хизмат кўрсатиш каби хизматларни бажаради. Халқаро ва маҳаллий авиация мутахассислари жамоалари орқали биз мижозларимизга ва ҳамкорларимизга лойиҳаларни самарали амалга оширишга олиб келадиган энг яхши ечимларни таклиф қиламиз.",
        intelligent_transport_systems:"Ҳарактни бошқариш, тирбандликни олдини олиш, хавфсизликни яхшилаш ва тўхташ жойларни ақлли ташкиллаштириш, шунингдек шовқин ва ҳавонинг ифлосланишини назорат қилиш орқали Ақлли транспорт тизимлари яшаш ва ишлаш шароитларига тўғридан-тўғри таъсир қилади. Сўнгги тизимлар, жараёнлар ва технологиялар комбинацияси орқали, PCC ақлли транспорт тизимлари шўналишида кенг кўламда консалтинг хизматларини кўрсатади.",
        pavement_management:"Йўл қопламаларини бошқаришни такомиллаштириш, йўл тармоқларини оптималлаштириш ва хавфсизликни яхшилаш, яъни Йўл қопламаларини бошқариш орқали яшаш ва ишлаш шароитларига бевосита таъсир қилинади. Сўнгги тизимларни ва жараёнларни бирлаштириш орқали, PCC йўл қопламаларини бошқариш масалалари бўцича кенг кўламли консалтинг хизматларини кўрсатади.",
        public_transport_management:"Жамоат транспорти бу шаҳарни жонлантирадиган ва яшаш шароитларига бевосита таъсир қиладиган муҳим шаҳар томиридир. Инновацион, индивидуал ва экологик ечимларни қўллаган ҳолда, PCC шаҳар ҳокимиятларига йўл ҳаракати ва жамоат транспорти масалалари юзасидан кенг кўламли консалтинг хизматларини таклиф қилади.",
        railways_and_metro:"PCC етакчи халқаро шериклари билан биргаликда темир йўл ва ер ости (метро) йўл масалалари бўйича консалтинг хизматларини, шунингдек, лекин бу билан чегараланмаган ҳолда, муҳандислик лойиҳалаштириш, қурилиши, фойдаланиш ва техник хизмат кўрсатиш каби хизматларини кўрсатади. Темир йўл мутахассисларининг халқаро ва маҳаллий жамоалари орқали мижозларимизга ва шерикларимизга темир йўл ва ер ости (метро) йўл лойиҳаларини самарали, инновацион ва экологик хавфсиз амалга оширишга олиб келадиган энг яхши ечимларни таклиф қиламиз.",
        roads_and_highways:"Йўллар ва автомагистраллар ҳар қандай мамлакат ривожланиши учун жуда муҳимдир, чунки йўлнинг мавжудлиги ва сифати иқтисодиётнинг ҳар бир соҳасига таъсир қилади. Бундан ташқари, замонавий лойиҳалаштириш, кенг қамровли ва мураккаб ечимлар, йўл ва автомобил йўллари инфратузилмаси келажакда юзага келадиган муаммоларни қондириш учун жуда муҳимдир.\n" +
            "PCC кенг кўламда муҳандислик ва консалтинг хизматларини кўрсатади, шу жумладан, лекин бу билан чегараланмаган ҳолда техник-иқтисодий асослар, харидлар, лойиҳалаштириш, қурилиш, янги ёки мавжуд автомобил йўл лойиҳаларини назорат қилиш каби хизматларни кўрсатади.",
        bridges_and_tunnels:"Шаҳарсозлик, иқтисодий, ижтимоий ва экологик қоидалар ва талаблар, ер ости ва ер усти жойлардан фойдаланишда замонавий ёндашув қўлланилишини талаб қилиб, самарали, хавфсиз ва барқарор ечимлар таъминланишини талаб қилади.\n" +
            "Инновацион, хавфсиз, самарали ва барқарор ечимларни қўллаган ҳолда,  PCC замонавий жамият муаммоларини ечишга қаратилган хизматларни кўрсатади.",
        traffic_and_transport_planning:"PCC етакчи халқаро шериклари билан биргаликда йўл ҳаракати ва транспортни режалаштириш хизматларини тақдим этади. Халқаро ва маҳаллий транспорт мутахассисларидан ва йўл ҳаракати муҳандисларидан ташкил топган жамоаларимиз, мижозларимизга ва шерикларимизга индивидуал ва энг илғор ечимларни таклиф қилади.",
        telecommunication:"PCC етакчи халқаро шериклари билан биргаликда телекоммуникация соҳасида консалтинг хизматларини, шунингдек, лекин бу билан чегараланмаган ҳолда муҳандислик лойиҳалаштириш, қурилиши, фойдаланиш ва техник хизмат кўрсатиш каби хизматларни кўрсатади. Халқаро ва маҳаллий телекоммуникация мутахассисларидан ташкил топган жамоаларимиз орқали телекоммуникация лойиҳаларини самарали амалга оширишга олиб келадиган энг илғор ечимларни тақдим этамиз.",
        sectors_part1:"PCC муҳандислик ва консалтинг шу жумладан, лекин бу билан чекланмаган ҳолда режалаштириш, лойиҳалаштириш, харидлар, етказиб бериш ва ишга тушириш каби хизматларнинг кенг кўламини профессионал даражада кўрсатади.",
        sectors_part2:"PCC тажрибаси сув таъминоти ва атроф-муҳитни муҳофаза қилиш, энергетика ва ресурслар, транспорт ва телекоммуникация каби жадал ривожланаётган Ўзбекистон иқтисодиётининг барча асосий тармоқларини қамраб олади.",
        sectors_part3:"PCC лойиҳани муваффақиятли амалга ошириш ва натижаларга олиб келадиган индивидуал, самарали ва барқарор ечимларни тақдим этади.",
        sectors_part4:"PCC вазифаларни бажаришда замонавий, инновацион ва амалий ечимларга таянади.",
        //------------------------------
        water_and_environment_title:`Сув ва атроф муҳит`,
        energy_and_resources_title:`Энергетика ва ресурслар `,
        transport_title:`Транспорт`,
        telecommunication_title:"Телекоммуникация",
        plan:"Режа",
        design:"Лойиҳа",
        procurement:"Харидлар",
        deliver:"Етказиб бериш",
        decommission:"Эксплуатациядан чиқариш",
        initiation:"Ташаббусчилик ва концептуаллаштириш",
        sectors_title:"Секторлар",
    },
}