import React, { useEffect, useState } from "react";
import { LOCALES } from "../../../i18n/Locale";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { observer } from "mobx-react-lite";

const CreatePanel = observer(({data,setNewCourse}) => {
    const [newFeedback, setNewFeedback] = useState(data);
    useEffect(() => {
        setNewFeedback(data);
    }, [data]);
    const [language, setLang] = useState("en-US");
    const [nameEdit, setNameEdit] = useState("");
    const [textEdit, setTextEdit] = useState("");
    const [positionEdit, setPositionEdit] = useState("");
    const [isChange, setChange] = useState(true);
    useEffect(() => {
        setNameEdit(newFeedback.name[language]);
        setTextEdit(newFeedback.text[language]);
        setPositionEdit(newFeedback.position[language]);
    }, [language,isChange]);
    const handleChangeText = (e) => {
        const { name: fieldName, value } = e.target;
        let obj = newFeedback;
        switch (fieldName)
        {
            case "name":
                setNameEdit(value)
                break;
            case "text":
                setTextEdit(value)
                break;
            case "position":
                setPositionEdit(value);
                break;
        }
        obj[fieldName][language] = value;
        setNewFeedback(obj);
    };
    const handleChangeLanguage = (e) => {
        setLang(e.target.value);
    };
    const [date,setDate] = useState(null);
    const handleChangeDate = (date)=>{
        setDate(date)
        let obj = newFeedback;
        obj.date = date;
        setNewFeedback(obj);
    }
    return (
        <div>
            <div className={"admin_create_news"}>
                <div>
                    <div className={"topmenu_createmenu"}>
                        <div
                            className="lang"
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            {Object.keys(LOCALES).map((_language, key) => {
                                return (
                                    <Form.Check inline key={key}>
                                        <Form.Check.Input
                                            type={"radio"}
                                            name={"group2"}
                                            value={LOCALES[_language]}
                                            checked={
                                                LOCALES[_language] === language
                                            }
                                            onClick={(e) => {
                                                handleChangeLanguage(e);
                                                setChange(!isChange);
                                            }}
                                        />
                                        <Form.Check.Label
                                            style={{
                                                alignSelf: "center",
                                                marginLeft: "5px",
                                            }}
                                        >
                                            {_language}
                                        </Form.Check.Label>
                                    </Form.Check>
                                );
                            })}
                        </div>
                        <DatePicker
                            selected={date}
                            onChange={handleChangeDate}
                            className={"text_title_send"}
                            withPortal
                            dateFormat={"dd.MM.yyyy"}
                            closeOnScroll={true}
                            isClearable
                            placeholderText={"Date"}/>
                        <input
                            type={"text"}
                            className={"text_title_send"}
                            value={nameEdit}
                            name={"name"}
                            placeholder={"Name"}
                            onChange={(e) => {
                                handleChangeText(e);
                            }}
                        />
                        <input
                            type={"text"}
                            className={"text_title_send"}
                            value={positionEdit}
                            name={"position"}
                            placeholder={"Position"}
                            onChange={(e) => {
                                handleChangeText(e);
                            }}
                        />
                        <textarea
                            className={"text_area_news"}
                            placeholder={"Text"}
                            name={"text"}
                            value={textEdit}
                            onChange={(e) => {
                                handleChangeText(e);
                            }}
                        />
                    </div>

                </div>
            </div>
        </div>
    );
});

export default CreatePanel;
