import React from 'react';

const AdminMain = () => {
    return (
        <div style={{width:"100%"}}>
            <div style={{width:"100%",textAlign:"center"}}><h1>ADMIN PANEL</h1></div>
        </div>
    );
};

export default AdminMain;