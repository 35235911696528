import { LOCALES } from "./Locale";

export const messageAbout={
    [LOCALES.ENGLISH]:{
        about_us_title:"About us",
        foundation_title:"Foundation",
        partners_title:"Partners",
        members_title:"Members",
        projects_title:"Projects",
        polices_strategies_title:"Polices & Strategies",
        download_title:"Downloads",
        services_title:"Services",
        upcoming_related_courses:"Upcoming Related Courses",
        about_us:"Pro Consult Company is a multi-industrial engineering, management and development consultancy, who delivers verified and tailored solutions for the clients and partners. Using extensive experience and practice of its experts PCC provides consultancy and engineering services across the project lifecycle: initiation, feasibility studies and researches, design, procurement, construction, comissioning, operation and maintenance. PCC prioritizes quality, efficiency and innovativeness, integrates national knowledge with international experience and is committed to deliver fit for purpose and sustainable solutions. PCC does not set competition to show its superiority, instead PCC cooperates with the leaders of the piece of area when it is wise to provide the best result for customers and clients and recieve true recognition.",
        foundation:"PCC was established by a group of ex.public servants worked within the engineering and consulting industry for long years. Expertise and profound knowledge of the industry showed necessity to develop certain qualifications and skills of specialists which will result in further qualitative development of the industry. It was then decided to establish a platform - company which will focus on professional advanced education, providing state of the art studies to the specialists, because we believe that Only skilled person makes main sense to the development.",
        polices_strategies:"The policy and strategy of Pro consult Company is based on good faith fulfillment of its obligations, based on socially-oriented and benevolent attitude towards partners, clients and employees. Corporate Social Responsibility, Anti-corruption and Integrity",
        download_url_1:"Partnership Strategy",
        download_url_2:"Code of Conduct",
        download_url_3:"Code of integrity",
        services:"Services",
        initiation_conception_title:"Initiation & Conception",
        plan_title:"Plan",
        design_title:"Design",
        procurement_title:"Procurement",
        deliver_title:"Deliver",
        decommission_title:"Decommission",
        logo_table:"Logo",
        member_table:"Member organisation",
        country_table:"Country",
        specialization_table:"Specialization",
        member_status:"Member status",
        partners_table:"Partners",
        more_info:"more info",
        see_also:"See also",
        all_services:"All Services",
        all_course:"all course",
    },
    [LOCALES.RUSSIAN]:{
        about_us_title:"О нас",
        foundation_title:"Учреждение",
        partners_title:"Партнеры",
        members_title:"Членство",
        projects_title:"Проекты",
        polices_strategies_title:"Политика и стратегии",
        download_title:"Загрузки:",
        services_title:"Услуги",
        about_us:`Pro consult company - многопрофильная консалтинговая компания в области инжиниринга, управления и развития, предоставляющая надежные и индивидуальные решения для своих клиентов и партнеров. Используя обширный опыт и практику своих экспертов, PCC предоставляет консультационные и инжиниринговые услуги в период всего жизненного цикла проекта включая: инициирование проекта, в том числе подготовку технико-экономического обоснования и исследования рынков, проектирование, организацию процедур закупа, строительство, ввода в эксплуатацию, а также эксплуатацию и техническое обслуживание. 
PCC ставит приоритетом качество, эффективность и инновационность при решении поставленных задач, объединяя национальные знания с международным опытом направленных на предоставление устойчивых решений соответствующих целям. 
Поэтому PCC не стремится конкурировать с компаниями, чтобы показать свое превосходство, вместо этого PCC осуществляет сотрудничество с компаниями -лидерами в своей области, на взаимовыгодных условиях, нацеленных на обеспечение наилучших результатов решения поставленных клиентами и заказчиками задач.`,
        foundation:`Компания PCC была создана группой экс.госслужащих, проработавших в инжиниринговой и консалтинговой отрасли долгие годы. Опыт и глубокие знания отрасли показали необходимость развития определенных квалификаций и навыков специалистов, которые приведут к дальнейшему качественному развитию отрасли. Тогда было решено создать платформу - компанию, которая изначально сосредоточится на профессиональном образовании, предоставляя специалистам передовое обучение, потому что считаем, что только высококвалифицированный специалист имеет основной смысл в развитии.`,
        polices_strategies:"Политика и стратегия Pro consult company основывается на добросовестном выполнении своих обязательств, основанном на социально ориентированном и доброжелательном отношении к партнерам, клиентам и сотрудникам компании. Корпоративная социальная ответственность, борьба с коррупцией и добросовестность",
        download_url_1:"Стратегия партнерства",
        download_url_2:"Кодекс поведения",
        download_url_3:"Кодекс добросовестности",
        services:"Сервисы",
        initiation_conception_title:"Инициация & Концепт",
        plan_title:"Планирование",
        design_title:"Проектирование ",
        procurement_title:"Закупки",
        deliver_title:"Поставка",
        decommission_title:"Вывод из эксплуатации",
        logo_table:"Логотип",
        member_table:"Организация",
        country_table:"Страна",
        specialization_table:"Специализация",
        member_status:"Статус членства",
        partners_table:"Партнер",
        more_info:"узнать больше",
        see_also:"Смотреть также",
        all_services:"Сервисы",
        upcoming_related_courses:"Предстоящие тематические курсы",
        all_course:"Все курсы",
    },
    [LOCALES.UZBEK]:{
        about_us_title:"Биз ҳақимизда",
        foundation_title:"Таъсис этилиш",
        partners_title:"Шериклар",
        members_title:"Аъзолар",
        projects_title:"Лойиҳалар",
        polices_strategies_title:"Сиёсат ва стратегиялар",
        download_title:"Юкламалар",
        services_title:"Хизматлар",
        upcoming_related_courses:"Бўлажак Тематик Курслар",
        about_us:`Pro consult company ўз мижозлари ва шериклари учун ишончли ва индивидуал ечимларни тақдим этадиган муҳандислик, менежмент ва ривожланиш соҳасидаги кўп тармоқли консалтинг компанияси. Ўз мутахассисларининг катта тажрибаси ва амалиётидан фойдаланган ҳолда, PCC лойиҳанинг бутун умри давомида консалтинг ва муҳандислик, шу жумладан: лойиҳани бошлаш, шу жумладан техник-иқтисодий асосларни тайёрлаш ва бозорни ўрганиш, лойиҳалаш, харид қилиш тартибларини ташкил этиш, қурилиш, ишга тушириш, шунингдек фойдаланиш ва техник хизмат кўрсатиш хизматларини тақдим этади.
PCC масалани ҳал қилишда сифат, самарадорлик ва инновационликни устун қўйган ҳолда, мақсадларга жавоб берадиган барқарор ечимларни тақдим этишга қаратилган миллий билимларни халқаро тажриба билан бирлаштиради.
Шунинг учун PCC ўзининг устунлигини кўрсатиш учун компаниялар билан рақобатлашишга интилмайди, аксинча PCC мижозлар томонидан қўйилган вазифаларни ҳал қилишнинг энг яхши натижаларини таъминлашга қаратилган ўзаро манфаатли шартларда соҳадаги етакчи компаниялар билан ҳамкорлик қилади.`,
        foundation:`PCC компанияси кўп йиллар давомида муҳандислик ва консалтинг соҳасида ишлаган собиқ давлат хизматчилари гуруҳи томонидан ташкил этилган. Соҳадаги тажриба ва чуқур билим асосида, соҳани янада сифатли ривожлантиришда мутахассисларнинг муайян малака ва кўникмаларини ривожлантириш зарурлигини кўрсатди. Шу туфайли дастлаб профессионал таълимга эътибор берган, мутахассисларга илғор билимларни берадиган платформа - компанияни ташкил этиш қарор қилинган. Чунки, ривожланишнинг асосий мезони бу юқори малакали мутахассисдир.`,
        polices_strategies:`Pro consult company сиёсати ва стратегияси компаниянинг шериклари, мижозлари ва ходимларига нисбатан ижтимоий йўналтирилган ва дўстона муносабатларга асосланган ўз мажбуриятларини виждонан бажаришга асосланган. Корпоратив ижтимоий жавобгарлик, коррупцияга қарши курашиш ва ҳалоллик`,
        download_url_1:"Шерикчилик стратегияси",
        download_url_2:"Хулқ-атвор кодекси",
        download_url_3:"Ҳалоллик кодекси",
        services:"Хизматлар",
        initiation_conception_title:"Ташаббусчилик ва Концептуаллаштириш",
        plan_title:"Режа",
        design_title:"Лойиҳа",
        procurement_title:"Харидлар",
        deliver_title:"Етказиб бериш",
        decommission_title:"Эксплуатациядан чиқариш",
        logo_table:"Логотип",
        member_table:"Аъзолик",
        country_table:"Давлат",
        specialization_table:"Ихтисослик",
        member_status:"Аъзолик статуси",
        partners_table:"Шерик",
        more_info:"батафсил маълумот",
        all_course:"Ҳамма курслар",


    },
}
