import { LOCALES } from "./Locale";
export const messageService = {
    [LOCALES.ENGLISH]:{
        initiation_conception_title:"Initiation & Conception",
        plan_title:"Plan",
        design_title:"Design",
        procurement_title:"Procurement",
        deliver_title:"Deliver",
        decommission_title:"Decommission",
        initial_planning_title:"Initial planning",
        conceptualising_title:"Conceptualising",
        master_planning_title:"Master planning",
        market_research_title:"Market research, surveying and analyzing",
        economic_and_financial_title:"Economic and Financial planning",
        risk_analyses_title:"Risk analyses",
        feasibility_studies_and_business_plans_title:"Feasibility studies and Business plans",
        detailed_and_front_title:"Detailed and Front End Engineering Design (FEED) - adaptation",
        interior_and_exterior_design_title:"Interior and Exterior design",
        preparation_of_bidding_documents_title:"Preparation of Bidding documents",
        tendering_support_title:"Tendering support. Procurement (ICB and NCB requirements)",
        advising_on_logistics_title:"Advising on Logistics and Importing of goods and services (customs)",
        project_and_contract_management_title:"Project and contract management",
        construction_supervision_title:"Construction supervision",
        testing_and_commissioning_title:"Testing and commissioning",
        decommissioning_and_closure_title:"Decommissioning and closure",
        rehabilitation_title:"Rehabilitation, modernization and reconstruction",
        water_and_environment_title:`Water and Environment`,
        energy_and_resources_title:`Energy and Resources`,
        transport_title:`Transport`,
        telecommunication_title:"Telecommunication",
        upcoming_related_courses:"Upcoming Related Courses",
        all_course:"all course",
        see_also:"See also",
        all_sectors:"All Sectors",
        sectors:"Sectors",

        //------------------------------------------------
        initial_planning:"A clear view of the project aim is not always enough to even roughly measure all main risks. PCC extensive expertise may assist to point out main challenges to adopt plan and/or reconsider the potential.",
        conceptualising:"Along with initial planning support, PCC may assist its clients with project Concept preparation which will include major risks and challenges, milestones, helpful information and advices which will have worth.",
        master_planning:"By Master planning PCC will provide its' clients with a complex view and guidance on project implementation, consisting of strategic, economic, regulatory, technical, environmental and social issues. Master plan will equip clients with useful information on short-, mid-, and long-term project implementation stages.",
        market_research:"Any resultoriented project needs thorough developed Market research which will show real situation in the market and perspectives. PCC accurately and creatively conducts market surveys by thorough analyzes of datas based on evaluation of market, competitors, trends, risks, prices, opportunities and other main and minor factors. PCC knows that correct strategic business decision is one of the key factor of successful project implementation. PCC owns needed resources to conduct reliable market research.",
        economic_and_financial:"It is clear that economic and financial aspects of project implementation is the most interesting part, as it forecasts by numbers results Client's may gain by project implementation. PCC has an extensive experience and expertise in developing economic and financial plans which will accurately reflect calculated expectations.",
        risk_analyses:"Sometimes insignificant unaccounted risk may bring significant difficulties for successful project implementation. Therefore, PCC is committed to study, check and predict any possible risk project may have during implementation. Based on Client needs, PCC may provide risk analyzes services locally or internationally with partners to bring the best result client relies.",
        feasibility_studies_and_business_plans:"Feasibility and sustainability of the project depends on technical, economic, environmental, social and operational feasibility proven by expert investigation. Success of further project implementation depends on quality of information these feasibility aspects reflect. Having received this service from PCC, Client's Feasibility study/Business plan will meet needed requirements and will satisfy expectation of the Client.",
        detailed_and_front:"Whether it is pre- or detailed FEED, according to local legislation it should be adapted to local norms and regulation. PCC provides such services, so that adapted engineering design fully satisfies both client and local agencies.",
        interior_and_exterior_design:"Using innovative design solutions and technical expertise, along with constant communication with Clients and listening to their requirements and expectations, PCC delivers result which will satisfy thorough expectation interior and exterior design.",
        preparation_of_bidding_documents:"PCC provides Clients' with services to prepare bidding documents and bid offers according to International and National competitive bidding requirements. PCC's expertise ensures quality of prepared documents and therefore success at tender. ",
        tendering_support:"Skilled staff of PCC has a good experience with International Financial Institutes and Government Institutions that works well to assist our Clients at Tendering. PCC is committed to provide its Clients with full information on suppliers and prices, terms and conditions of delivery and payment, customs and taxes, ensuring success of procurement works.",
        advising_on_logistics:"While procurement of goods and services and determining supplier and price, it is worth to have precise information on available routes (logistics) of delivery, their cost, time frame, customs and taxes. This information may change Clients made decision on supplier and the cost of goods and services. PCC provides such precise information on all available options on logistics and bind with delivery expenses, so that Client is confident about expenses and result.",
        project_and_contract_management:"PCC helps its Clients to plan and manage complex projects and contracts from start to finish, delivering them in a effective and quality manner. Regardless of where you stand in terms of planning the work or execution, we offer services to help to be successful.",
        construction_supervision:"PCC possess technical expertise and experience in projects in Uzbekistan to deliver projects in time and within budget. The Client is always right, therefore PCC is committed to deliver cost effective, practical and sustainable solutions best serving to our clients needs.",
        testing_and_commissioning:"PCC provides services to help its Clients to conduct testing and commissioning of the project. PCC supports its Client through successful testing and commissioning.",
        decommissioning_and_closure:"When an asset’s useful life comes to an end, PCC provides assistance to bring the project to a successful close with decommissioning and closure services. PCC provides assistance in development of decommissioning plan and closure framework.",
        rehabilitation:"Whether it is rehabilitation, modernization or reconstruction, PCC and its team of experienced experts will offer our clients the best way of implementation through effective, innovative and sustainable solutions.",
        services_title:"Services",
        services_part1:"Pro-consult company offers comrehensive engineering and consulting services across all main sectors of economy delivering tailored, effective and sustainable outcomes.",
        services_part2:"Using modern, innovative and practical solutions, PCC delivers reliable performance to a range of industry sectors, including Water and Environment, Energy and Resources, Transport and Telecommunication.",
        services_part3:"Depending on Clients role, PCC can act as an owner's, contractor's and/or lender's Engineer for the implementation of a project.",
        services_part4:"Whether it is modernization, reconstruction or a new development, PCC competently manages works keeping the Client informed and consulted on all major developments so that Clients expections are exceeded.",

    },
    [LOCALES.RUSSIAN]:{
        initiation_conception_title:"Инициация & Концепт",
        plan_title:`План`,
        design_title:`Дизайн`,
        deliver_title:`Доставка`,
        decommission_title:`Вывод из эксплуатации`,
        initiation_title:"Инициация и концепция",
        procurement_title:"Закупка",
        initial_planning_title:"Первоначальное планирование",
        conceptualising_title:"Концептуализация",
        master_planning_title:"Главное планирование",
        market_research_title:"Маркетинговое исследование, изучение и анализ",
        economic_and_financial_title:"Экономическое и финансовое планирование",
        risk_analyses_title:"Анализ рисков",
        feasibility_studies_and_business_plans_title:"Технико-экономическое обоснование и бизнес планы",
        detailed_and_front_title:"Детальное и предварительное технико-экономическое обоснование (ТЭО) - адаптация",
        interior_and_exterior_design_title:"Внутренний и внешний дизайн",
        preparation_of_bidding_documents_title:"Подготовка конкурсной документации",
        tendering_support_title:"Содействие в проведении тендеров. Закуп (требования МКТ и НКТ)",
        advising_on_logistics_title:"Консультирование по логистике и импорту товаров и услуг (томожня",
        project_and_contract_management_title:"Управление проектом и контрактом",
        construction_supervision_title:"Управление строительством",
        testing_and_commissioning_title:"Испытания и ввод в эксплуатацию",
        decommissioning_and_closure_title:"Завершение проекта и закрытие",
        rehabilitation_title:"Реабелитация, модернизация и реконструкция",
        telecommunication_title:"Телекоммуникации",
        water_and_environment_title:`Вода и окружающая среда`,
        energy_and_resources_title:`Энергия и ресурсы`,
        transport_title:`Транспорт`,
        upcoming_related_courses:"Предстоящие курсы",
        sectors:"Cекторы",
        all_course:"Все курсы",
        see_also:"Смотреть также",
        all_sectors:"Секторы",
        //------------------------------------------------
        initial_planning:"Иметь четкое представление о цели проекта не всегда достаточно, чтобы зачастую приблизительно оценить все основные риски. Богатый опыт PCC поможет выявить основные проблемы для принятия плана и/или переосмыслить потенциал.  ",
        conceptualising:"Наряду с содействием в первоначальном планировании, PCC поможет своим клиентам в подготовке Концепции проекта, которая будет включать основные риски и проблемы, этапы, полезную информацию и советы, которые будут иметь ценность.  ",
        master_planning:"Путем составления главного плана, PCC предоставит своим клиентам комплексное видение и руководство по реализации проекта, состоящее из стратегических, экономических, нормативных, технических, экологических и социальных вопросов. Главный план обеспечит клиентов полезной информацией на краткосрочном, среднесрочном и долгосрочном этапах реализации проекта.",
        market_research:"Любой нацеленный на результат проект, нуждается в тщательно разработанном маркетинговом исследовании, которое покажет реальную ситуацию на рынке и перспективы. PCC с точностью и креативно проводит исследования рынка путем тщательного анализа данных на основе оценки рынка, конкурентов, тенденций, рисков, цен, возможностей и других основных и второстепенных факторов. PCC знает, что правильное стратегическое бизнес решение является одним из ключевых факторов успешной реализации проекта. PCC располагает необходимыми ресурсами для проведения надежных исследований рынка.",
        economic_and_financial:"Очевидно, что экономические и финансовые аспекты реализации проекта - это самый интересующий раздел, поскольку они прогнозируют в цифрах результаты, которые Клиент может получить в результате реализации проекта. Компания PCC имеет большой опыт и знания в разработке экономических и финансовых планов, которые будут точно отражать рассчитанные ожидания.",
        risk_analyses:"Иногда, незначительный, неучтенный риск может привести к значительным трудностям для успешной реализации проекта. Поэтому, PCC стремится изучить, выверить и спрогнозировать все возможные риски, которые могут возникнуть в ходе реализации проекта. Исходя из потребностей клиента, PCC может предоставить услуги по анализу рисков на местном или международном уровне совместно с партнерами, для достижения наилучшего результата, на который рассчитывает клиент.   ",
        feasibility_studies_and_business_plans:"Целесообразность и устойчивость проекта зависит от технической, экономической, экологической, социальной и эксплуатационной осуществимости, подтвержденной экспертным исследованием. Успех дальнейшей реализации проекта зависит от качества информации, отраженной в этих аспектах ТЭО. Получив данную услугу от PCC, ТЭО/Бизнес-план клиента будет соответствовать необходимым требованиям и удовлетворит ожидания Клиента. ",
        detailed_and_front:"Будь то предварительное или детальное ТЭО проекта, в соответствии с местным законодательством он должен быть адаптирован к местным нормам и правилам. PCC предоставляет такие услуги, которые обеспечат адаптацию проекта и полностью удовлетворят как заказчика, так и местные органы.",
        interior_and_exterior_design:"Используя инновационные проектные решения и технические знания, а также постоянно общаясь с клиентами и прислушиваясь к их требованиям и ожиданиям, PCC обеспечивает результат, который удовлетворит все ожидания по внутреннему и внешнему дизайну.",
        preparation_of_bidding_documents:"PCC предоставляет клиентам услуги по подготовке тендерной документации и конкурсных предложений в соответствии с международными и национальными требованиями конкурсных торгов. Опыт PCC обеспечивает качество подготовленной документации и, следовательно, успех на тендере.",
        tendering_support:"Квалифицированный штат PCC имеет хороший опыт работы с международными финансовыми институтами и государственными учреждениями, что позволяет оказывать содействие нашим клиентам в проведении тендеров. PCC стремится предоставить своим клиентам полную информацию о поставщиках и ценах, сроках и условиях поставки и оплаты, таможенных пошлинах и налогах, обеспечивая успех закупочных работ. ",
        advising_on_logistics:"Когда срок полезного использования актива подходит к концу, компания PCC предоставляет помощь в успешном завершении проекта с помощью услуг по выводу из эксплуатации и закрытию. PCC оказывает помощь в разработке плана вывода из эксплуатации и концепции закрытия.",
        project_and_contract_management:"PCC помогает своим клиентам планировать и управлять сложными проектами и контрактами от начала до конца, выполняя их эффективно и качественно. Независимо от того, на каком этапе планирования или выполнения работ вы находитесь, PCC предлагает услуги, которые помогут добиться успеха.",
        construction_supervision:"Компания PCC обладает техническими знаниями и опытом в реализации проектов в Узбекистане, что позволяет ей выполнять проекты в срок и в рамках бюджета. Клиент всегда прав, поэтому PCC стремится предоставлять экономически эффективные, практичные и устойчивые решения, наилучшим образом отвечающие потребностям наших клиентов. ",
        testing_and_commissioning:"PCC предоставляет услуги по оказанию содействия клиентам в проведении испытаний и вводе проекта в эксплуатацию. PCC оказывает поддержку своим клиентам в успешном проведении испытаний и ввода в эксплуатацию.",
        decommissioning_and_closure:"Когда срок полезного использования актива подходит к концу, компания PCC предоставляет помощь в успешном завершении проекта с помощью услуг по выводу из эксплуатации и закрытию. PCC оказывает помощь в разработке плана вывода из эксплуатации и концепции закрытия.",
        rehabilitation:"Будь то реабилитация, модернизация или реконструкция, PCC и ее команда опытных экспертов предложат нашим клиентам наилучший способ реализации посредством эффективных, инновационных и устойчивых решений.",
        services_title:"Услуги",
        services_part1:"PCC предлагает комплексные инжиниринговые и консультационные услуги во всех основных секторах экономики, обеспечивая индивидуальные, эффективные и устойчивые результаты. ",
        services_part2:"Используя современные, инновационные и практические решения, компания PCC обеспечивает надежную работу в различных отраслях промышленности, включая водоснабжение и охрану окружающей среды, энергетику и ресурсы, транспорт и телекоммуникации. ",
        services_part3:"В зависимости от роли заказчика, PCC может выступать в качестве инженера заказчика, подрядчика и/или кредитора при реализации проекта. ",
        services_part4:"Будь то модернизация, реконструкция или новое строительство, PCC компетентно руководит работами, информируя и консультируя клиента по всем основным вопросам, чтобы ожидания клиента были превзойдены",

    },
    [LOCALES.UZBEK]:{
        initiation_conception_title:"Ташаббусчилик ва концептуаллаштириш",
        initial_planning_title:"Дастлабки режалаштириш",
        conceptualising_title:"Концептуаллаштириш",
        master_planning_title:"Бош режалаштириш",
        market_research_title:"Маркетинг тадқиқоти, ўрганиш ва таҳлил қилиш ",
        economic_and_financial_title:"Иқтисодий ва молиявий режалаштириш",
        risk_analyses_title:"Ҳавфларни таҳлил қилиш",
        feasibility_studies_and_business_plans_title:"Техник-иқтисодий асос ва бизнес режалар",
        detailed_and_front_title:"Батафсил ва дастлабки техник-иқтисодий асос (ТИА) - мувофиқлаштириш",
        interior_and_exterior_design_title:"Ички ва ташқи дизайн",
        preparation_of_bidding_documents_title:"Конкурс хужжатларини тайёрлаш",
        tendering_support_title:"Тендер савдоларини ўтказишда ёрдам. Харид қилиш (ХТС ва МТС талаблари) ",
        advising_on_logistics_title:"Товар ва хизматларни импорт қилиш ва логистика бўйича консултациялар бериш  ",
        project_and_contract_management_title:"Лойиҳа ва шартномаларни бошқариш",
        construction_supervision_title:"Қурилишни бошқариш",
        testing_and_commissioning_title:"Синовдан ўтказиш ва ишга тушириш",
        decommissioning_and_closure_title:"Лойиҳани фойдаланишдан чиқариш ва ёпиш",
        rehabilitation_title:"Реабелитация, модернизация ва реконструкция",
        water_and_environment_title:`Сув ва атроф муҳит`,
        energy_and_resources_title:`Энергетика ва ресурслар `,
        transport_title:`Транспорт`,
        telecommunication_title:"Телекоммуникация",
        plan_title:"Режа",
        design_title:"Лойиҳа",
        procurement_title:"Харидлар",
        deliver_title:"Етказиб бериш",
        decommission_title:"Эксплуатациядан чиқариш",
        services:"Хизматлар",
        upcoming_related_courses:"Бўлажак тематик курслар",
        all_course:"Ҳамма курслар",
        see_also:"See also",
        all_services:"Хизматлар",
        sectors:"Секторлар",

        //------------------------------------------------
        initial_planning:"Лойиҳа мақсадини аниқ билиш ҳар доим ҳам барча асосий хавфларни ўлча олиш учун етарли эмас. PCC кенг кўламли тажрибаси, режани қабул қилиш ва/ёки потенциалини қайта кўриб чиқиш учун асосий муаммоларни аниқлаб беришда ёрдам беради.",
        conceptualising:"Дастлабки режалаштиришда кўмаклашиш билан бир қаторда, PCC ўз мижозларига катта хавф ва муаммоларни, муҳим босқичларни, фойдали ва қимматли маълумотларни ўз ичига олган лойиҳа концепциясини тайёрлашда ёрдам беради.",
        master_planning:"Бош режа тузиш орқали, PCC стратегик, иқтисодий, норматив, техник, экологик ва ижтимоий масалаларни акс эттирган, лойиҳани амалга ошириш комплекс кўриниши, ҳамда раҳбарлик  йўриқнома билан ўз мижозларини таъминлайди. Бош режа мижозларни лойиҳани амалга оширишда қисқа, ўрта ва узоқ муддатли босқичлар юзасидан фойдали маълумотлар билан таъминлайди.",
        market_research:"Ҳар қандай натижага қаратилган лойиҳани амалга оширишда, бозордаги ва истиқболларни реал вазиятини кўрсата оладиган пухта ишлаб чиқилган бозор тадқиқоти зарур. PCC бозорни, рақобатчиларни, тенденцияларни, хатарларни, нархларни, имкониятларни ва бошқа асосий ва иккиламчи омилларни баҳолаш асосида маълумотларни батафсил таҳлил қилиш орқали бозор тадқиқотини аниқ ва ижодий тайёрлайди. PCC мукаммал стратегик бизнес қарор, лойиҳани муваффақиятли амалга оширишнинг асосий омилидан бири эканлигини билади. PCC ишончли бозор тадқиқотини ўтказиш учун зарур ресурсларга эга.",
        economic_and_financial:"Иқтисодий ва молиявий кўрсаткичлар лойиҳанинг энг қизиқарли қисми, чунки бу кўрсаткичлар мижоз лойиҳа амалга оширишдан қозониши мумкин натижаларни рақамларда башорат қилади. PCC иқтисодий ва молиявий режаларни ишлаб чиқишда катта илм ва тажрибага эга бўлиб, ҳисоб-китоб қилинган кўрсаткичларни аниқ акс эттиради.",
        risk_analyses:"Баъзида, арзимаган, ҳисобга олинмаган хавф, лойиҳани муваффақиятли амалга оширишда катта қийинчиликларга олиб келиши мумкин. Шунинг учун, PCC лойиҳани амалга оширишда юзага келиши мумкин бўлган ҳар қандай хавфни чуқур ўрганади, текширади ва олдиндан кўрсатиб ўтади. Мижоз эҳтиёжларидан келиб чиққан ҳолда, PCC энг яхши натижага эришиш учун шериклар билан биргаликда маҳаллий ёки халқаро миқёсда хатарларни таҳлил қилиш хизматларини тақдим этади.",
        feasibility_studies_and_business_plans:"Лойиҳанинг маъқуллилиги ва барқарорлиги, эксперт текшируви билан тасдиқланган техник, иқтисодий, экологик, ижтимоий ва операцион жиҳатдан амалга ошириш мувофиқлигига боғлиқ. Лойиҳани келгусида амалга оширишнинг муваффақияти ушбу техник-иқтисодий жиҳатлар акс этган маълумотларнинг сифатига боғлиқ. Ушбу хизмат PCC томонидан кўрсатилганда, мижознинг техник-иқтисодий асоси/бизнес-режаси керакли талабларга жавоб бериб, мижозни қондиради.",
        detailed_and_front:"Дастлабки ёки батафсил техник-иқтисодий асос бўладими, маҳаллий қонунчиликка мувофиқ ТИА маҳаллий меъёрлар ва қоидаларга мувофиқлаштирилган бўлиши зарур. PCC бундай хизматларни тақдим этади, мувофиқлаштирилган ТИА мижоз ва маҳаллий идораларни тўлиқ қондиради.",
        interior_and_exterior_design:"Инновацион лойиҳа ечимларидан ва техник билимлардан фойдаланган ҳолда, ҳамда мижозлар билан доимий мулоқотда бўлиб, уларнинг талабларини ва умидларини инобатга олиш орқали, PCC ички ва ташқи дизайндан кутилаётган натижани тўлиқ таъминлайди.",
        preparation_of_bidding_documents:"PCC мижозларга халқаро ва миллий танлов савдолари талабларига мувофиқ тендер ҳужжатларни ва танлов таклифларини тайёрлаш бўйича хизматларни кўрсатади. PCC тажрибаси тайёрланган ҳужжатларнинг сифати орқали танловда муваффақиятни таъминлайди.",
        tendering_support:"PCC малакали ходимлари халқаро молия институтлари ва давлат идоралари билан ишлаш тажрибасига эга бўлиб, мижозларга тендер савдоларини ўтказишда ёрдам беришга тайёр . PCC мижозларга етказиб таъминлаб берувчилар ва нархлар, етказиб бериш ва тўлаш шартлари, божхона ва солиқлар тўғрисида тўлиқ маълумот бериб, харид ишларини муваффақиятли ўтказилишини таъминлайди.",
        advising_on_logistics:"Товарлар ва хизматларни харид қилишда, етказиб берувчи ва нархларни белгилашда, етказиб бериш мавжуд йўллари (логистикаси), уларнинг нархи, муддати, божхона ва солиқлар тўғрисида аниқ маълумотларга эга бўлиш зарур. Ушбу маълумотлар, етказиб берувчи, товарлар ва хизматлар қиймати бўйича мижоз қарорини ўзгартириши мумкин. PCC етказиб беришнинг (логистиканинг) барча мавжуд йўллари ва қиймати, ҳақида аниқ маълумот етказиб бериш орқали, мижоз харажатлар ва натижалари тўғрисида тўлиқ ишонч ҳосил қилади.",
        project_and_contract_management:"PCC мижозларига мураккаб лойиҳалар ва шартномаларни бошидан охиригача режалаштириш ва бошқаришда ёрдам беради, хизматларни самарали ва сифатли етказиб беради. Ишларни режалаштиришнинг ёки бажарилишнинг қандай босқичида бўлишидан қатъий назар, PCC  муваффақиятли иш бўлиши учун хизматларни кўрсатади.",
        construction_supervision:"PCC Ўзбекистонда лойиҳаларни ўз вақтида ва бюджет доирасида амалга ошириш учун техник тажриба ва тажрибага эга. Мижоз ҳар доим ҳақ, шуни инобатга олган ҳолда, PCC мижоз истаган натижани эришишга қаратилган энг яхши хизмат қиладиган иқтисодий самарали, амалий ва барқарор ечимларни тақдим этади.",
        testing_and_commissioning:"PCC мижозларига лойиҳани синовдан ўтказиш ва ишга тушириш хизматларини кўрсатади. PCC муваффақиятли синов ва ишга тушириш орқали мижозларга таянчли суюнчиқ бўлади.",
        decommissioning_and_closure:"Активдан фойдаланиш муддати тугагач, PCC лойиҳани фойдаланишдан чиқариш ва ёпиш хизматлари билан муваффақиятли якунлашга ёрдам беради. PCC фойдаланишдан чиқиш ва ёпилиш режаларини ишлаб чиқишда хизмат кўрсатади.",
        rehabilitation:"Реабелитация, модернизация ёки реконструкция бўлишидан қатъи назар, PCC тажрибали мутахассислар жамоаси самарали, инновацион ва барқарор ечимлар орқали мижозларимизга энг қулай таклифларни беришади. ",
        services_title:"Хизматлар",
        services_part1:"PCC индивидуал, самарали ва барқарор натижаларни таъминлайдиган, иқтисодиётнинг барча асосий тармоқларида кенг қамровли муҳандислик ва консалтинг хизматларини кўрсатади.",
        services_part2:"Замонавий, инновацион ва амалий ечимлардан фойдаланган ҳолда PCC турли соҳаларда, шу жумладан сув таъминоти ва атроф-муҳитни муҳофаза қилиш, энергетика ва ресурслар, транспорт ва телекоммуникацияларда ишончли ишни таъминлайди.",
        services_part3:"Буюртмачининг ролига қараб, PCC лойиҳани амалга оширишда буюртамачи, пудратчи ва/ёки кредитор муҳандиси вазифасини бажариши мумкин.",
        services_part4:"Модернизация, реконструкция ёки янги қурилиш бўладими, PCC баркамол ишни бошқариб, мижозни барча асосий масалалар бўйича хабардор ва консультация қилади, ва шу орқали мижоз кутаётган натижани ортиғи билан бажаришга интилади .",
    },
}